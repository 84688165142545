/*********************************************************************************

	Template Name: Torres is an App landing  React template.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|
	|
	|__Element Styles
	|	|___ button 
	|	|___ section-title 
	|	|___ service
	|	|___ feature
	|	|___ pricing
	|	|___ testimonial
	|	|___ screenshot
	|	|___ blog
	|
	|__Template Styles
	|	|___ banner 
	|	|___ about 
	|	|___ download 
	|	|___ breadcrumb
	|	|___ blogdetails 
	|	|___ footer 
	|	|___ landing 
	|	|___ color 
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';

/**************************************
    Header Styles
***************************************/

@import 'header/header';

/**************************************
    Element Styles
***************************************/

@import 'element/button';
@import 'element/section-title';
@import 'element/service';
@import 'element/feature';
@import 'element/pricing';
@import 'element/testimonial';
@import 'element/screenshot';
@import 'element/video';
@import 'element/blog';

/**************************************
	Template Styles
***************************************/

@import 'template/banner';
@import 'template/about';
@import 'template/download';
@import 'template/breadcrumb';
@import 'template/blogdetails';
@import 'template/footer';
@import 'template/landing';
@import 'template/color';

.text-red{
    color: #ff3333;
}